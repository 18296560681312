<template>
  <div class="modal-hider" id="modal-register">
    <div class="modal-title">Créer un compte</div>
    <form action="#" id="registerForm">
      <slot></slot>
      <p>Essayez le logiciel Dentalsirh gratuitement pendant 15 jours.</p>
      <div class="form-group">
        <p
          v-if="error.length"
          v-for="(message, index) in error"
          :key="index"
          id="register-form-error"
          class="error-text"
        >
          {{ message }}
        </p>
        <b-link v-if="showResendVerificationBtn" href="/email/verify" class="verify-link">
          Vous ne trouvez pas l'email de confirmation ?
        </b-link>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="workspace"
          >Nom de votre espace de travail (dénomination sociale)</label
        >
        <div class="form-group-input">
          <input
            id="workspace"
            type="text"
            name="workspace"
            placeholder="Nom de cabinet, société ou entreprise..."
            required=""
            v-model="credentials.workspace_name"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="firstname">Prénom</label>
        <div class="form-group-input">
          <input
            id="firstname"
            type="text"
            name="firstname"
            placeholder="Prénom"
            required=""
            v-model="credentials.firstname"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="lastname">Nom</label>
        <div class="form-group-input">
          <input
            id="lastname"
            type="text"
            name="lastname"
            placeholder="Nom"
            required=""
            v-model="credentials.lastname"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="email">Email</label>
        <div class="form-group-input">
          <input
            id="register-email"
            type="email"
            name="email"
            placeholder="Adresse email"
            required=""
            v-model="credentials.email"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="tel">Numéro de téléphone</label>
        <div class="form-group-input">
          <input
            id="tel"
            type="text"
            name="tel"
            placeholder="Numéro de téléphone"
            required
            v-model="credentials.tel"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="password">Mot de passe</label>
        <div class="form-group-input">
          <input
            id="register-password"
            type="password"
            name="password"
            placeholder="Mot de passe"
            autocomplete="on"
            required=""
            v-model="credentials.password"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="password_confirmation"
          >Confirmation de mot de passe</label
        >
        <div class="form-group-input">
          <input
            id="password_confirmation"
            type="password"
            name="password_confirmation"
            placeholder="Mot de passe"
            autocomplete="on"
            required=""
            v-model="credentials.password_confirmation"
          />
        </div>
      </div>
      <div class="form-footer">
        <b-button
          class="button"
          id="registerBtn"
          type="submit"
          @click.prevent="submit"
          :disabled="isLoading"
        >
          <template v-if="!isLoading">Commencer l'essai de 15 jours</template>
          <template v-else>Chargement...</template>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      isLoading: false,
      showResendVerificationBtn: false,
      credentials: {
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        password_confirmation: '',
        workspace_name: '',
        tel: '',
      },
      error: [],
    }
  },

  methods: {
    async submit() {
      this.isLoading = true
      //Error messages

      if (!this.credentials.tel) {
        this.error.push('Vous devez renseigner votre numéro de téléphone');
        this.isLoading = false;
        return false;
      }

      if (!this.credentials.email || !this.credentials.password) {
        this.error.push('Vous devez renseigner votre email et mot de passe')
        this.isLoading = false

        return false
      }

      if (this.credentials.password !== this.credentials.password_confirmation) {
        this.error.push('Les mots de passe doivent être identiques')
        this.isLoading = false

        return false
      }

      if (this.credentials.password.length < 8) {
        this.error.push('Votre mot de passe doit contenir au moins 8 caractères')
        this.isLoading = false

        return false
      }

      if (
        !this.credentials.workspace_name ||
        !this.credentials.firstname ||
        !this.credentials.lastname
      ) {
        this.error.push('Vous devez remplir tous les champs.')
        this.isLoading = false

        return false
      }

      //Fix Cookies problem > logout + remove cookie
      const res = await axios.get('/api/users/isLoggedIn')
      if (res.data.logged_in) {
        //Bug seems to be caused by already logged user
        await axios.post('/logout').then(() => {
          console.log('user logout')
        })
        Cookies.remove('dentalsirh_session')
        Cookies.remove('XSRF-TOKEN')
      }

      let credentials = this.credentials
      await axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          axios
            .post(
              '/register',
              {
                email: credentials.email,
                tel: credentials.tel,
                password: credentials.password,
                password_confirmation: credentials.password_confirmation,
                firstname: credentials.firstname,
                lastname: credentials.lastname,
                workspace_name: credentials.workspace_name,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(() => {
              this.loginUser()
            })
            .catch((err) => {
              switch (err.response.status) {
                case 400:
                  if(err.response.data.tel && err.response.data.email) {
                    this.error.push('Le format du numéro de téléphone est invalide.')
                    this.error.push('Cet email existe déjà.');
                  } else if (err.response.data.tel) {
                    this.error.push('Le format du numéro de téléphone est invalide.');
                  } else if (err.response.data.email) {
                    this.error.push('Cet email existe déjà.');
                  }
                  break

                default:
                  this.error.push("Une erreur s'est produite.")
                  break
              }

              this.isLoading = false
            })
        })
        .catch((err) => {
          this.error.push("Erreur, votre demande n'a pas pu être envoyée.")
          this.isLoading = false
        })
    },
    async loginUser() {
      let email = this.credentials.email
      let password = this.credentials.password

      await axios
        .post(
          '/login',
          {
            email,
            password,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(() => {
          window.location.replace('/tasks')
        })
        .catch((err) => {
          switch (err.response.status) {
            case 422:
              this.error.push('Email et/ou mot de passe incorrect(s).')
              break

            case 403:
              this.error.push('Vous devez confirmer votre adresse email.')
              this.showResendVerificationBtn = true
              break

            default:
              this.error.push("Une erreur s'est produite.")
              break
          }
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.verify-link {
  text-decoration: underline;
  color: #009cde;
  display: block;
  margin-top: 0.5rem;
}
</style>

import axios from 'axios'
import axiosCancel from 'axios-cancel'

axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axiosCancel(axios)
const singleton = axios.create()

export default singleton

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from './services/api'

window.axios = axios
window.Vue = require('vue')

Vue.use(BootstrapVue)

//Custom component to use into laravel blade views
Vue.component('auth-modal-form', require('./components/form/AuthModalForm.vue').default)
Vue.component('register-modal-form', require('./components/form/RegisterModalForm.vue').default)

const app = new Vue({
    el: '#appHome'
})
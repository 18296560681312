<template>
  <div class="modal-hider" id="modal-login">
    <div class="modal-title">Se connecter</div>
    <form action="#" id="loginForm">
      <slot></slot>
      <div v-if="error.length" class="form-group">
        <p
          v-for="(message, index) in error"
          :key="index"
          id="login-form-error"
          class="error-text"
        >
          {{ message }}
        </p>
        <b-link v-if="showResendVerificationBtn" href="/email/verify" class="verify-link">
          Vous ne trouvez pas l'email de confirmation ?
        </b-link>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="email">Identifiant</label>
        <div class="form-group-input">
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Adresse email"
            required=""
            v-model="credentials.email"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-group-label" for="password">Mot de passe</label>
        <div class="form-group-input">
          <input
            id="password"
            type="password"
            name="password"
            placeholder="Mot de passe"
            autocomplete="on"
            required=""
            v-model="credentials.password"
          />
        </div>
      </div>
      <div class="form-footer">
        <b-button
          class="button"
          id="loginBtn"
          type="submit"
          @click.prevent="submit"
          :disabled="isLoading"
        >
          <template v-if="!isLoading">Je me connecte</template>
          <template v-else>Chargement...</template>
        </b-button>
        <div class="form-footer-small">
          <a href="#modal-password-reset" data-modal-open="">Mot de passe oublié ?</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      isLoading: false,
      showResendVerificationBtn: false,
      credentials: {
        email: '',
        password: '',
      },
      error: [],
    }
  },

  methods: {
    async submit() {
      this.isLoading = true
      //Error messages
      if (!this.credentials.email || !this.credentials.password) {
        this.error.push('Vous devez renseigner votre email et mot de passe')
        this.isLoading = false

        return false
      }

      //Fix Cookies problem > logout + remove cookie
      const res = await axios.get('/api/users/isLoggedIn')
      if (res.data.logged_in) {
        //Bug seems to be caused by already logged user
        await axios.post('/logout').then(() => {
          console.log('user logout')
        })
        Cookies.remove('dentalsirh_session')
        Cookies.remove('XSRF-TOKEN')
      }

      await axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          this.loginUser()
        })
        .catch((response) => {
          this.error.push("Erreur, votre demande n'a pas pu être envoyée.")
          this.isLoading = false
        })
    },
    async loginUser() {
      let email = this.credentials.email
      let password = this.credentials.password

      await axios
        .post(
          '/login',
          {
            email,
            password,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(() => {
          window.location.replace('/tasks')
        })
        .catch((err) => {
          switch (err.response.status) {
            case 422:
              this.error.push('Email et/ou mot de passe incorrect(s).')
              break

            case 403:
              this.error.push('Vous devez confirmer votre adresse email.')
              this.showResendVerificationBtn = true
              break

            default:
              this.error.push("Une erreur s'est produite.")
              break
          }
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.verify-link {
  text-decoration: underline;
  color: #009cde;
  display: block;
  margin-top: 0.5rem;
}
</style>
